.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::-moz-selection {
  background: #0090ff;
  color: #fff;
}
::selection {
  background: #0090ff;
  color: #fff;
}
* {
  -webkit-tap-highlight-color: rgba(0, 144, 255, 0.1);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.page {
  background: #fff;
}
html {
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  color: #0d0d0d;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 200;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.5;
  margin: 0;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  body {
    font-size: 1rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
strong,
b {
  font-weight: 500!important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1 {
  letter-spacing: calc((1.3125rem + 0.4375
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.3125rem;
  color: #2e6da0;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h1 {
    font-size: calc(1.3125rem + 0.4375
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h1 {
    font-size: 1.75rem;
  }
}
h2 {
  letter-spacing: calc((1.1875rem + 0.375
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #2e6da0;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h2 {
    font-size: calc(1.1875rem + 0.375
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h2 {
    font-size: 1.5625rem;
  }
}
h3,
.steuernews_preview .title {
  letter-spacing: calc((1rem + 0.1875
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1rem;
  color: #2e6da0;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h3,
  .steuernews_preview .title {
    font-size: calc(1rem + 0.1875
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h3,
  .steuernews_preview .title {
    font-size: 1.1875rem;
  }
}
h4 {
  letter-spacing: calc((0.9375rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  color: #2e6da0;
  font-family: "Ubuntu", sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h4 {
    font-size: calc(0.9375rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h4 {
    font-size: 1.0625rem;
  }
}
h5 {
  letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 0.875rem;
  font-family: "Ubuntu", sans-serif;
  font-weight: 200;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5 {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  h5 {
    font-size: 1rem;
  }
}
h6 {
  height: 0;
  margin: 0;
  overflow: hidden;
  width: 0;
}
p {
  margin: 0 0 0.75em;
}
a {
  color: #0090ff;
  text-decoration: none;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #0d0d0d;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(0, 144, 255, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: underline;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: underline;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
  top: -2.5rem;
}
a[tabindex="-1"][id]:after {
  content: "";
}
a.touch_link {
  color: #0d0d0d;
  display: block;
  text-decoration: underline;
}
a.touch_link .title {
  color: #0090ff;
  display: inline-block;
  margin-bottom: 0.375em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #0d0d0d;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.1875rem solid rgba(0, 144, 255, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: underline;
}
.button {
  border-radius: 0;
  font-size: 1rem;
  padding: 0.15em 1.8em;
  background: #0090ff;
  border: 0.125rem solid #0082e6;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.button > span {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: calc(2.75rem - (0.125rem * 2));
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.button .icon {
  fill: #fff;
  height: 1.5rem;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 1.5rem;
}
.button .text {
  font-weight: 200;
  padding: 0.5em 0;
  white-space: nowrap;
}
.button .icon + .text {
  padding-left: 0.5em;
}
.button:focus {
  -webkit-box-shadow: 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
          box-shadow: 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
  outline: 0;
  text-decoration: none;
  background: #33a6ff;
  border-color: #0082e6;
  color: #fff;
}
.button:focus .icon {
  fill: #fff;
}
.button:hover {
  text-decoration: none;
  background: #33a6ff;
  border-color: #0090ff;
  color: #fff;
}
.button:hover .icon {
  fill: #fff;
}
.button:active,
.button.active {
  -webkit-box-shadow: 0 0 0.375rem #0073cc inset;
          box-shadow: 0 0 0.375rem #0073cc inset;
  background: #0090ff;
  border: 0.125rem solid #0073cc;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.button:active > span,
.button.active > span {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: calc(2.75rem - (0.125rem * 2));
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.button:active .icon,
.button.active .icon {
  fill: #fff;
  height: 1.5rem;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 1.5rem;
}
.button:active .text,
.button.active .text {
  font-weight: 200;
  padding: 0.5em 0;
  white-space: nowrap;
}
.button:active .icon + .text,
.button.active .icon + .text {
  padding-left: 0.5em;
}
@media (min-width: 30em) {
  .button {
    padding: 0.15em 2.7em;
  }
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 700;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.75em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.5em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.5em;
}
table {
  border-bottom: 0.125rem solid #cccccc;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 700;
  text-align: left;
}
td,
th {
  border-top: 0.125rem solid #cccccc;
  padding: 0.5em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.75em;
  padding: 0;
}
ol > li {
  padding-left: 1.5em;
  position: relative;
}
ol > li:before {
  color: #0090ff;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 700;
  left: 0;
  line-height: 1.5;
  position: absolute;
  text-align: right;
  width: 1.125em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: url("../images/list_style_image_circle.svg");
  margin: 0 0 0.75em;
  padding-left: 1.125em;
}
ul > li {
  padding-left: 0.375em;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list dl {
  border-bottom: 0.125rem solid #cccccc;
  margin: 0.75em 0;
}
@media (min-width: 35em) {
  .defined_list dl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.defined_list dt {
  border-top: 0.125rem solid #cccccc;
  font-weight: 700;
  padding: 0.75em 0;
}
@media (min-width: 35em) {
  .defined_list dt {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 40%;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.75em 0.75em 0.75em 0;
  }
}
.defined_list dd {
  margin: 0;
}
@media (min-width: 35em) {
  .defined_list dd {
    border-top: 0.125rem solid #cccccc;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 60%;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.75em 0.5em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.5em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.5em;
}
figure.floatright {
  float: right;
  margin-left: 1.5em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.375em;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #0d0d0d;
  -webkit-box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.375rem #0090ff inset, 0 0 0 0.375rem rgba(0, 144, 255, 0.2) !important;
          box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.375rem #0090ff inset, 0 0 0 0.375rem rgba(0, 144, 255, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
.form_disclaimer {
  background: #e6e6e6;
  border-radius: 0;
  color: #0d0d0d;
  font-size: 85%;
  margin-bottom: 0.75em;
  padding: 1em 1.5em;
}
::-webkit-input-placeholder {
  color: #4d4d4d;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #4d4d4d;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #4d4d4d;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #0090ff;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #0090ff;
  color: #fff;
  display: none;
  font-weight: 700;
  margin-right: 0.375em;
  padding: 0 0.375em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.1875rem + 0.25
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #0d0d0d;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding: 0.75em 0 0;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.1875rem + 0.25
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media (min-width: 93.75em) {
  .legend {
    font-size: 1.4375rem;
  }
}
.label {
  display: inline-block;
  margin-bottom: 0.375em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.125rem solid #2e6da0;
  border-radius: 0;
  color: #0d0d0d;
  margin: 0 0 1em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #0090ff;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #0090ff;
  -webkit-box-shadow: 0 0 0.375rem #0090ff inset, 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
          box-shadow: 0 0 0.375rem #0090ff inset, 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
  color: #0d0d0d;
}
.input:focus,
.textarea:focus {
  border-color: #0090ff;
  -webkit-box-shadow: 0 0 0.375rem #0090ff inset, 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
          box-shadow: 0 0 0.375rem #0090ff inset, 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
  color: #0d0d0d;
}
.input {
  height: 2.75rem;
  padding: 0 0.75em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
}
.textarea {
  line-height: 1.5;
  overflow: hidden;
  padding: 0.75em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: 65%;
      flex-basis: 65%;
  height: 2.75rem;
  margin: 0 0 1em;
  position: relative;
}
.file.error .file_name {
  border-color: #0090ff;
}
.file .file_name {
  background: #fff;
  border: 0.125rem solid #cccccc;
  border-radius: 0 0 0 0;
  color: #0d0d0d;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 100%;
          flex: 0 1 100%;
  height: 2.75rem;
  line-height: calc(2.75rem - (0.125rem * 2));
  overflow: hidden;
  padding: 0 0.75em;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 8.125rem;
}
.file .file_button {
  border-radius: 0 0 0 0;
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  padding: 0 1.5em;
  z-index: 10;
  background: #0090ff;
  border: 0.125rem solid #0082e6;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.file .file_button > span {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: calc(2.75rem - (0.125rem * 2));
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.file .file_button .icon {
  fill: #fff;
  height: 1.5rem;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 1.5rem;
}
.file .file_button .text {
  font-weight: 200;
  padding: 0.5em 0;
  white-space: nowrap;
}
.file .file_button .icon + .text {
  padding-left: 0.5em;
}
.file .file_button.focus {
  -webkit-box-shadow: 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
          box-shadow: 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
  background: #33a6ff;
  border-color: #0082e6;
  color: #fff;
}
.file .file_button.focus .icon {
  fill: #fff;
}
.file [type="file"] {
  bottom: 0;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 2.75rem;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
}
.file [type="file"]:hover ~ .file_button {
  background: #33a6ff;
  border-color: #0090ff;
  color: #fff;
}
.file [type="file"]:hover ~ .file_button .icon {
  fill: #fff;
}
.file [type="file"]:active ~ .file_button {
  -webkit-box-shadow: 0 0 0.375rem #0073cc inset;
          box-shadow: 0 0 0.375rem #0073cc inset;
  background: #0090ff;
  border: 0.125rem solid #0073cc;
  color: #fff;
  display: inline-block;
  text-decoration: none;
  -webkit-transition: background 300ms, border 300ms, color 300ms;
  transition: background 300ms, border 300ms, color 300ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.file [type="file"]:active ~ .file_button > span {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: calc(2.75rem - (0.125rem * 2));
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.file [type="file"]:active ~ .file_button .icon {
  fill: #fff;
  height: 1.5rem;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
  width: 1.5rem;
}
.file [type="file"]:active ~ .file_button .text {
  font-weight: 200;
  padding: 0.5em 0;
  white-space: nowrap;
}
.file [type="file"]:active ~ .file_button .icon + .text {
  padding-left: 0.5em;
}
.control {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0.375em 0.75em 0.75em 0;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #0090ff;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #0090ff;
  -webkit-box-shadow: 0 0 0.375rem #0090ff inset, 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
          box-shadow: 0 0 0.375rem #0090ff inset, 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
  color: #0d0d0d;
}
.control input:focus ~ .indicator {
  border-color: #0090ff;
  -webkit-box-shadow: 0 0 0.375rem #0090ff inset, 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
          box-shadow: 0 0 0.375rem #0090ff inset, 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
  color: #0d0d0d;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-right: 0.75em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.125rem solid #2e6da0;
  border-radius: 0;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #0d0d0d;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
}
.select_wrapper {
  display: block;
}
.select {
  background: #fff;
  border: 0.125rem solid #2e6da0;
  border-radius: 0;
  color: #0d0d0d;
  cursor: pointer;
  display: block;
  margin: 0 0 1em;
  padding: 0 0.75em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #0d0d0d;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.75em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #0090ff;
}
.select.error.focus {
  border-color: #0090ff;
  -webkit-box-shadow: 0 0 0.375rem #0090ff inset, 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
          box-shadow: 0 0 0.375rem #0090ff inset, 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
  color: #0d0d0d;
}
.select.focus {
  border-color: #0090ff;
  -webkit-box-shadow: 0 0 0.375rem #0090ff inset, 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
          box-shadow: 0 0 0.375rem #0090ff inset, 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
  color: #0d0d0d;
}
.select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #0d0d0d;
  cursor: pointer;
  height: calc(2.75rem - (0.125rem * 2));
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.select select::-ms-expand {
  display: none;
}
.select select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #0d0d0d;
}
.select select:focus::-ms-value {
  background: none;
  color: #0d0d0d;
}
@media (min-width: 35em) {
  .formular .button_wrapper {
    text-align: right;
  }
}
footer button {
  height: 2.75rem;
  width: 100%;
}
button {
  cursor: pointer;
  margin: 0.75em 0;
  outline: 0;
  overflow: hidden;
  width: 100%;
}
@media (min-width: 35em) {
  button {
    width: auto;
  }
}
button::-moz-focus-inner {
  border: 0;
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  70% {
    background: rgba(0, 144, 255, 0.1);
    -webkit-box-shadow: 0 0 0 1.25rem rgba(0, 144, 255, 0.1);
            box-shadow: 0 0 0 1.25rem rgba(0, 144, 255, 0.1);
  }
  100% {
    background: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  70% {
    background: rgba(0, 144, 255, 0.1);
    -webkit-box-shadow: 0 0 0 1.25rem rgba(0, 144, 255, 0.1);
            box-shadow: 0 0 0 1.25rem rgba(0, 144, 255, 0.1);
  }
  100% {
    background: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
}
@-webkit-keyframes zoom_out {
  0% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes zoom_out {
  0% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@media screen {
  .page {
    min-width: 20rem;
  }
  .container {
    margin: 0 auto;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .span1_xsmall {
    -ms-flex-preferred-size: 8.33333333%;
        flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .span2_xsmall {
    -ms-flex-preferred-size: 16.66666667%;
        flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .span3_xsmall {
    -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .span4_xsmall {
    -ms-flex-preferred-size: 33.33333333%;
        flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .span5_xsmall {
    -ms-flex-preferred-size: 41.66666667%;
        flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .span6_xsmall {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .span7_xsmall {
    -ms-flex-preferred-size: 58.33333333%;
        flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .span8_xsmall {
    -ms-flex-preferred-size: 66.66666667%;
        flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .span9_xsmall {
    -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .span10_xsmall {
    -ms-flex-preferred-size: 83.33333333%;
        flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .span11_xsmall {
    -ms-flex-preferred-size: 91.66666667%;
        flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .span12_xsmall {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  header {
    margin: 0 auto;
    max-height: 125rem;
    max-width: 187.5rem;
    position: relative;
  }
  .startpage header {
    height: 65vh;
  }
  .header_content {
    background-color: rgba(255, 255, 255, 0.9);
    left: 0;
    padding: 2.5em 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .header_content .container {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .company_logo {
    display: block;
    max-width: 14rem;
  }
  .author_hor {
    display: inline-block;
    fill: #0d0d0d;
    height: 0.5625rem;
    line-height: 0.5625rem;
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    width: 4.6875rem;
  }
  .author_ver {
    display: inline-block;
    fill: #0d0d0d;
    height: 4.6875rem;
    line-height: 4.6875rem;
    width: 0.5625rem;
  }
  .go_to_bottom {
    background-color: #0090ff;
    border-radius: 50%;
    bottom: 10vw;
    display: none;
    height: 3.5625rem;
    padding: 1em 0.8em 0.8em 0.8em;
    position: absolute;
    right: 10vw;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 3.5625rem;
  }
  .go_to_bottom svg {
    fill: #fff;
  }
  .go_to_bottom:hover,
  .go_to_bottom:active,
  .go_to_bottom.active,
  .go_to_bottom:focus {
    background-color: #33a6ff;
  }
  .header_picture {
    overflow: hidden;
  }
  .header_picture img {
    display: block;
    height: auto;
    position: relative;
    width: 100%;
  }
  .startpage .header_picture {
    height: 100vh;
  }
  .header_image img {
    display: block;
    margin: 0 auto;
  }
  .header_background {
    background-position: 25% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: relative;
    width: 100%;
  }
  main {
    display: block;
    margin: 2em 0;
    min-height: 12.5rem;
  }
  .flex_span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .breadcrumbs {
    display: none;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-bottom: 0.75em;
  }
  .breadcrumbs p {
    margin: 0 0.375em 0 0;
  }
  .breadcrumbs ol {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .breadcrumbs .separator {
    margin: 0 0.375em;
  }
  .address {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    margin-bottom: 1em;
    padding: 0;
  }
  footer .address {
    margin-bottom: 0;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 2.25em;
  }
  .contact_wrapper .address {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
  .contact_wrapper .address > span {
    display: block;
  }
  .contact_wrapper .contact_data {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin: 0;
  }
  .contact {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .contact > span {
    margin-right: 0.2em;
  }
  .address_vcard_button {
    display: inline-block;
    margin: 0.5em 0 2em 0;
    width: 100%;
  }
  .address_vcard_button a {
    width: 100%;
  }
  .address_call_button {
    display: inline-block;
    margin: 1em 0 0 0;
    width: 100%;
  }
  .address_call_button a {
    width: 100%;
  }
  .opening_times .title {
    padding-top: 0.75em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 2.25em;
  }
  .onlinetools_popup_overview {
    background-color: #2e6da0;
    color: #fff;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    margin: 3em 0 2em 0;
    padding: 4.5em 3em 3em 3em;
    position: relative;
    width: 100%;
  }
  .onlinetools_popup_overview:before {
    border-color: #fff transparent transparent transparent;
    border-style: solid;
    border-width: 2.25em 2.25em 0 2.25em;
    content: "";
    display: block;
    height: 0;
    left: 3em;
    position: absolute;
    top: 0;
    width: 0;
  }
  .onlinetools_popup_overview .overview_content {
    display: block;
    position: relative;
    z-index: 9999;
  }
  .onlinetools_popup_overview li {
    margin-bottom: 1.5em;
  }
  .onlinetools_popup_overview a {
    color: #fff;
    text-decoration: none;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .onlinetools_popup_overview a .title {
    color: #fff;
    text-decoration: none;
  }
  .onlinetools_popup_overview h4 {
    color: #fff;
    margin-bottom: 1em;
  }
  .onlinetools_popup_overview .icon {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 0.3125rem solid #173751;
    border-radius: 50%;
    bottom: 3em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 9em;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    opacity: 0.5;
    position: absolute;
    right: 3em;
    width: 9em;
    z-index: 5;
  }
  .onlinetools_popup_overview .icon svg {
    fill: #173751;
    height: 4.5em;
    width: 4.5em;
  }
  .onlinetools_popup_overview .button {
    background: transparent;
    border: 0.125rem solid #fff;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-top: 0.75em;
    padding: 0.15em 1.2em;
  }
  .onlinetools_popup_overview .button > span {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: calc(2.75rem - (0.125rem * 2));
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .onlinetools_popup_overview .button .icon {
    fill: #fff;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .onlinetools_popup_overview .button .text {
    font-weight: 200;
    padding: 0.5em 0;
    white-space: nowrap;
  }
  .onlinetools_popup_overview .button .icon + .text {
    padding-left: 0.5em;
  }
  .onlinetools_popup_overview .button:focus {
    -webkit-box-shadow: 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
            box-shadow: 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
    outline: 0;
    text-decoration: none;
    background: #fff;
    border-color: #fff;
    color: #0090ff;
  }
  .onlinetools_popup_overview .button:focus .icon {
    fill: #0090ff;
  }
  .onlinetools_popup_overview .button:hover {
    text-decoration: none;
    background: #fff;
    border-color: #fff;
    color: #0090ff;
  }
  .onlinetools_popup_overview .button:hover .icon {
    fill: #0090ff;
  }
  .onlinetools_popup_overview .button:active,
  .onlinetools_popup_overview .button.active {
    -webkit-box-shadow: 0 0 0.375rem #0073cc inset;
            box-shadow: 0 0 0.375rem #0073cc inset;
    background: #0090ff;
    border: 0.125rem solid #0073cc;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .onlinetools_popup_overview .button:active > span,
  .onlinetools_popup_overview .button.active > span {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: calc(2.75rem - (0.125rem * 2));
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .onlinetools_popup_overview .button:active .icon,
  .onlinetools_popup_overview .button.active .icon {
    fill: #fff;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .onlinetools_popup_overview .button:active .text,
  .onlinetools_popup_overview .button.active .text {
    font-weight: 200;
    padding: 0.5em 0;
    white-space: nowrap;
  }
  .onlinetools_popup_overview .button:active .icon + .text,
  .onlinetools_popup_overview .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .servicecenter .onlinetools_popup_overview {
    margin-top: 1em;
  }
  .infolist_overview {
    background-color: #2e6da0;
    color: #fff;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    margin: 1em 0 2em 0;
    padding: 3.75em 1.5em 1.5em 1.5em;
    position: relative;
    width: 100%;
  }
  .infolist_overview ul {
    list-style: disc;
  }
  .infolist_overview:before {
    border-color: #fff transparent transparent transparent;
    border-style: solid;
    border-width: 2.25em 2.25em 0 2.25em;
    content: "";
    display: block;
    height: 0;
    left: 3em;
    position: absolute;
    top: 0;
    width: 0;
  }
  .infolist_overview .overview_content {
    display: block;
    position: relative;
    z-index: 9999;
  }
  .infolist_overview li {
    margin-bottom: 1.5em;
  }
  .infolist_overview li:last-child {
    margin-bottom: 0;
  }
  .infolist_overview a {
    color: #fff;
    text-decoration: none;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .infolist_overview a .title {
    color: #fff;
    text-decoration: none;
  }
  .infolist_overview h3 {
    color: #fff;
    margin-bottom: 2em;
  }
  .infolist_overview .icon {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 0.3125rem solid #173751;
    border-radius: 50%;
    bottom: 3em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 9em;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    opacity: 0.5;
    position: absolute;
    right: 3em;
    width: 9em;
    z-index: 5;
  }
  .infolist_overview .icon svg {
    fill: #173751;
    height: 4.5em;
    width: 4.5em;
  }
  .servicecenter.folder_content > .entry,
  .servicecenter.folder_content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
  .download_overview {
    background-color: #2e6da0;
    color: #fff;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    margin: 1em 0 2em 0;
    padding: 3.75em 1.5em 1.5em 1.5em;
    position: relative;
    width: 100%;
  }
  .download_overview:before {
    border-color: #fff transparent transparent transparent;
    border-style: solid;
    border-width: 2.25em 2.25em 0 2.25em;
    content: "";
    display: block;
    height: 0;
    left: 3em;
    position: absolute;
    top: 0;
    width: 0;
  }
  .download_overview a {
    color: #fff;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    text-decoration: none;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .download_overview a .title {
    color: #fff;
    text-decoration: none;
  }
  .download_overview a {
    margin-bottom: 1.5em;
  }
  .download_overview a:last-child {
    margin-bottom: 0;
  }
  .download_overview h3 {
    color: #fff;
    margin-bottom: 2em;
  }
  .download_overview .touch_link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .download_overview .touch_link .title_wrapper {
    margin-bottom: 0.375em;
    padding-top: 0;
  }
  .download_overview .touch_link .title {
    margin: 0;
  }
  .download_overview p {
    margin: 0;
  }
  .download_overview .overview_content {
    display: block;
    position: relative;
    z-index: 9999;
  }
  .download_overview .icon {
    fill: #fff;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    height: 1.5rem;
    margin-right: 0.75em;
    width: 1.5rem;
  }
  .download_overview > .icon {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 0.3125rem solid #173751;
    border-radius: 50%;
    bottom: 3em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 9em;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    opacity: 0.5;
    position: absolute;
    right: 3em;
    width: 9em;
    z-index: 5;
  }
  .download_overview > .icon svg {
    fill: #173751;
    height: 4.5em;
    width: 4.5em;
  }
  .u_online_overview a.touch_link,
  .u_online_overview a.touch_link .title {
    text-decoration: none;
  }
  .u_online_overview .touch_link {
    margin-top: 2em;
  }
  .u_online_overview .touch_link h2 {
    color: #2e6da0;
    margin-left: 1em;
    position: relative;
  }
  .u_online_overview .touch_link h2:before {
    border-color: transparent transparent transparent #2e6da0;
    border-style: solid;
    border-width: 0.6em 0 0.6em 0.6em;
    content: "";
    display: block;
    height: 0;
    left: -1em;
    position: absolute;
    top: 0.1em;
    -webkit-transition: border 300ms;
    transition: border 300ms;
    width: 0;
  }
  .u_online_overview .touch_link:hover,
  .u_online_overview .touch_link.active,
  .u_online_overview .touch_link:active,
  .u_online_overview .touch_link:focus {
    color: #0090ff;
  }
  .u_online_overview .touch_link:hover h2,
  .u_online_overview .touch_link.active h2,
  .u_online_overview .touch_link:active h2,
  .u_online_overview .touch_link:focus h2 {
    color: #0090ff;
  }
  .u_online_overview .touch_link:hover h2:before,
  .u_online_overview .touch_link.active h2:before,
  .u_online_overview .touch_link:active h2:before,
  .u_online_overview .touch_link:focus h2:before {
    border-color: transparent transparent transparent #0090ff;
  }
  aside.sidebar {
    background-color: #2e6da0;
    color: #fff;
    display: none;
    padding: 3em 3em 3em 4.5em;
  }
  aside.sidebar h3,
  aside.sidebar p,
  aside.sidebar a {
    color: #fff;
  }
  aside.sidebar h3 {
    padding: 0;
  }
  aside.sidebar li {
    list-style-position: inside;
    list-style-type: disc;
  }
  aside.sidebar li.active a,
  aside.sidebar li:active a,
  aside.sidebar li:focus a {
    color: #fff;
    text-decoration: underline;
  }
  aside.sidebar .sidebar_text {
    margin: 3.75em 0;
    position: relative;
  }
  aside.sidebar .sidebar_text p {
    margin: 0;
  }
  aside.sidebar .sidebar_text:before,
  aside.sidebar .sidebar_text:after {
    border-color: transparent transparent transparent #fff;
    border-style: solid;
    border-width: 2.25em 0 2.25em 2.25em;
    content: "";
    display: block;
    height: 0;
    position: absolute;
    width: 0;
  }
  aside.sidebar .sidebar_text:before {
    left: -4.5em;
    top: -4.05em;
  }
  aside.sidebar .sidebar_text:after {
    bottom: -4.05em;
    left: -4.5em;
  }
  aside.sidebar .button {
    background: transparent;
    border: 0.125rem solid #fff;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-top: 2.25em;
    padding: 0.15em 1.2em;
  }
  aside.sidebar .button > span {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: calc(2.75rem - (0.125rem * 2));
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  aside.sidebar .button .icon {
    fill: #fff;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  aside.sidebar .button .text {
    font-weight: 200;
    padding: 0.5em 0;
    white-space: nowrap;
  }
  aside.sidebar .button .icon + .text {
    padding-left: 0.5em;
  }
  aside.sidebar .button:focus {
    -webkit-box-shadow: 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
            box-shadow: 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
    outline: 0;
    text-decoration: none;
    background: #fff;
    border-color: #fff;
    color: #0090ff;
  }
  aside.sidebar .button:focus .icon {
    fill: #0090ff;
  }
  aside.sidebar .button:hover {
    text-decoration: none;
    background: #fff;
    border-color: #fff;
    color: #0090ff;
  }
  aside.sidebar .button:hover .icon {
    fill: #0090ff;
  }
  aside.sidebar .button:active,
  aside.sidebar .button.active {
    -webkit-box-shadow: 0 0 0.375rem #0073cc inset;
            box-shadow: 0 0 0.375rem #0073cc inset;
    background: #0090ff;
    border: 0.125rem solid #0073cc;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  aside.sidebar .button:active > span,
  aside.sidebar .button.active > span {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: calc(2.75rem - (0.125rem * 2));
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  aside.sidebar .button:active .icon,
  aside.sidebar .button.active .icon {
    fill: #fff;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  aside.sidebar .button:active .text,
  aside.sidebar .button.active .text {
    font-weight: 200;
    padding: 0.5em 0;
    white-space: nowrap;
  }
  aside.sidebar .button:active .icon + .text,
  aside.sidebar .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .footer_sidebar .footer_image_wrapper {
    margin-bottom: 1.5em;
    overflow: hidden;
    position: relative;
  }
  .footer_sidebar .footer_image_wrapper:before {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #ffffff), color-stop(70%, rgba(255, 255, 255, 0)));
    background: linear-gradient(to bottom, #ffffff 10%, rgba(255, 255, 255, 0) 70%);
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
  }
  .footer_sidebar figure {
    margin: 0 auto;
    overflow: hidden;
    position: relative;
  }
  .footer_sidebar figure img {
    margin: 0 auto;
  }
  .footer_sidebar .footer_animated {
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-name: zoom_out;
            animation-name: zoom_out;
  }
  footer {
    margin-bottom: 3em;
  }
  footer .footer_line {
    background-color: #00005a;
    height: 0.625rem;
    margin: 3em 0;
    width: 100%;
  }
  footer .related_links {
    -ms-flex-item-align: end;
        align-self: flex-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    margin-top: 2em;
    width: 100%;
  }
  footer .related_links ul {
    -ms-flex-item-align: end;
        align-self: flex-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    width: 100%;
  }
  footer .related_links .link_legal_notice {
    margin-left: 1.6em;
    position: relative;
  }
  footer .related_links .link_legal_notice:before {
    background-color: #00005a;
    border-radius: 50%;
    content: "";
    display: block;
    height: 0.5rem;
    left: -42%;
    margin: 0.5em 0.5em 0 1em;
    position: absolute;
    top: -0.1875rem;
    width: 0.5rem;
  }
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #fff;
    color: #0090ff;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.375em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #0090ff;
    height: auto;
    left: 0.375em;
    top: 0.375em;
    width: auto;
  }
  abbr {
    text-decoration: none;
  }
  [role="tablist"] {
    margin-bottom: 1.5em;
  }
  * + [role="tablist"] {
    margin-top: 3em;
  }
  [role="tab"] {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-top: 0.125rem solid #cccccc;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 0.375em;
    min-width: 100%;
    padding: 1.5em 0;
  }
  [role="tab"] .info {
    -ms-flex-preferred-size: 90%;
        flex-basis: 90%;
  }
  [role="tab"] .title {
    display: block;
    font-size: 90%;
    margin-bottom: 1em;
    margin-right: 0.75em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .description {
    color: #0d0d0d;
    display: block;
    font-weight: 400;
    letter-spacing: calc((0.875rem + 0.125
                                 * (100vw - 20rem)
                                 / (93.75 - 20))
                                 / 1000 * 0);
    font-size: 0.875rem;
  }
  [role="tab"] .icon {
    -ms-flex-item-align: start;
        align-self: flex-start;
    display: block;
    fill: #0090ff;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    height: 1.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #0090ff;
    padding-bottom: 0;
  }
  [role="tab"][aria-expanded="true"] .description {
    font-weight: 400;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:focus {
    border-color: #0090ff;
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #0090ff;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.5em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .edit_in_cms {
    display: none;
  }
  .cookie_banner {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #e6e6e6;
    border-top: 0.125rem solid #cccccc;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 85%;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9000;
  }
  .cookie_banner p {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    margin: 0;
    padding: 1em 0.75em 1em 1.5em;
  }
  .cookie_banner .close {
    cursor: pointer;
    display: block;
    fill: #0090ff;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    height: 2.75rem;
    padding: 0.75em;
    width: 2.75rem;
  }
  .dialog_wrapper {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    left: 0;
    overflow: auto;
    padding: 1.5em 0;
    position: fixed;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: 7100;
  }
  .dialog_wrapper.show {
    visibility: visible;
  }
  .dialog_overlay {
    background: rgba(13, 13, 13, 0.5);
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    width: 100%;
    z-index: 0;
  }
  .dialog_overlay.show {
    opacity: 1;
    visibility: visible;
  }
  [role="dialog"] {
    background: #fff;
    border-radius: 0;
    -webkit-box-shadow: 0 0.0625rem 0.375rem rgba(13, 13, 13, 0.3), 0 0.0625rem 0.25rem rgba(13, 13, 13, 0.2);
            box-shadow: 0 0.0625rem 0.375rem rgba(13, 13, 13, 0.3), 0 0.0625rem 0.25rem rgba(13, 13, 13, 0.2);
    margin: -0.625rem auto 0;
    max-height: 28.125rem;
    max-width: 35em;
    min-width: 18.5rem;
    opacity: 0;
    outline: 0;
    padding: 1.5em;
    position: relative;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    width: calc(100% - 1.5em);
    z-index: 10;
  }
  [role="dialog"].show {
    margin-top: 0;
    opacity: 1;
    visibility: visible;
  }
  [role="dialog"] .title {
    padding: 0 1.875em 0 0;
  }
  [role="dialog"] .description {
    padding: 0 0 0.75em 0;
  }
  [role="dialog"] .close {
    display: block;
    fill: #0090ff;
    height: 2.75rem;
    margin-left: auto;
    padding: 0.25em;
    position: absolute;
    right: 0.75em;
    top: 1em;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 2.75rem;
  }
  [role="dialog"] .close:focus,
  [role="dialog"] .close:hover {
    fill: #0d0d0d;
  }
  .dropdown_menu {
    display: none;
    margin: 2em 0 0 0;
  }
  .dropdown_menu > ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
  }
  .dropdown_menu > ul > li {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-right: 1.5em;
    position: relative;
  }
  .dropdown_menu > ul > li:hover > a,
  .dropdown_menu > ul > li.hover > a {
    color: #2e6da0;
  }
  .dropdown_menu > ul > li:hover ul,
  .dropdown_menu > ul > li.hover ul {
    opacity: 1;
    visibility: visible;
  }
  .dropdown_menu > ul > li:after {
    background-color: #00005a;
    border-radius: 50%;
    content: "";
    display: block;
    height: 0.75rem;
    position: absolute;
    right: -1em;
    top: 37%;
    width: 0.75rem;
  }
  .dropdown_menu > ul > li:last-child {
    margin-right: 0;
  }
  .dropdown_menu > ul > li:last-child:after {
    display: none;
  }
  .dropdown_menu > ul > li > a {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #0d0d0d;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 120%;
    font-weight: 400;
    padding: 0.75em;
    text-decoration: none;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li > a.active {
    color: #0090ff;
  }
  .dropdown_menu > ul > li > ul {
    background: #2e6da0;
    left: 50%;
    opacity: 0;
    padding: 1.5em 2em 1em 1em;
    position: absolute;
    top: 3.75rem;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    -webkit-transition: opacity 360ms;
    transition: opacity 360ms;
    visibility: hidden;
    z-index: 1000;
  }
  .dropdown_menu > ul > li > ul:target {
    visibility: visible;
  }
  .dropdown_menu > ul > li > ul a {
    color: #fff;
    display: block;
    padding: 0 0 0.5em 2.5em;
    position: relative;
    text-decoration: none;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li > ul a:hover {
    text-decoration: underline;
  }
  .dropdown_menu > ul > li > ul a:focus {
    outline-offset: -0.125rem;
  }
  .dropdown_menu > ul > li > ul a.active {
    text-decoration: underline;
  }
  .dropdown_menu > ul > li > ul a:before {
    background-color: #fff;
    border-radius: 50%;
    content: "";
    display: block;
    height: 0.625rem;
    left: 0;
    margin: 0.5em 0.5em 0 1em;
    position: absolute;
    top: 0;
    width: 0.625rem;
  }
  .google_maps {
    margin: 0 0 0.75em;
  }
  .google_maps .map {
    background: #e6e6e6;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 0.75em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    background: #2e6da0;
    border-color: #2e6da0;
    color: #0d0d0d;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #0d0d0d;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio svg {
    fill: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    -webkit-box-shadow: 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
            box-shadow: 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
    z-index: 15;
    background: #33a6ff;
    border-color: #0082e6;
    color: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode_entry {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    height: 2.75rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    background: #fff;
    border: 0.125rem solid #2e6da0;
    color: #0d0d0d;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: calc(2.75rem - (0.125rem * 2));
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #0d0d0d;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .text {
    font-weight: 200;
    padding: 0.5em 0;
    white-space: nowrap;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.5em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #0d0d0d;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0 0 0 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0 0 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0;
    color: #0090ff;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .google_maps .direction {
    margin: 0.75em 0;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .button_wrapper button {
    margin-bottom: 1em;
    padding-bottom: 0;
    padding-top: 0;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.75em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.125rem solid #cccccc;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.125rem solid #cccccc;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.75em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .img_container.arbeitnehmer_online_svg_2_small {
    max-width: 18.125rem;
  }
  .img_container.arbeitnehmer_online_svg_2_small .svg_resize {
    padding-bottom: 634.48275862%;
  }
  .img_container.arbeitnehmer_online_svg_2 {
    margin: 0 0 1.5em;
    max-width: 28.125rem;
  }
  .img_container.arbeitnehmer_online_svg_2 .svg_resize {
    padding-bottom: 148.88888889%;
  }
  .arbeitnehmer_online_svg_2_small {
    display: block;
    margin: 1.5em auto;
  }
  .arbeitnehmer_online_svg_2 {
    display: none;
  }
  .arbeitnehmer_online_2_headline {
    fill: #0d0d0d;
    font-family: "Ubuntu", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .arbeitnehmer_online_2_headline .strong {
    fill: #0d0d0d;
    font-weight: 700;
  }
  .arbeitnehmer_online_text {
    fill: #0d0d0d;
    font-family: "Ubuntu", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .arbeitnehmer_online_company_logo {
    fill: #0d0d0d;
  }
  .arbeitnehmer_online_icon {
    fill: #0090ff;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .arbeitnehmer_online_button .normal {
    display: block;
  }
  .arbeitnehmer_online_button .hover {
    display: none;
  }
  .arbeitnehmer_online_button:hover .normal {
    display: none;
  }
  .arbeitnehmer_online_button:hover .hover {
    display: block;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_icon {
    fill: #0090ff;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_euro_icon,
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_warning_icon,
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_check_icon {
    fill: #0090ff;
  }
  .arbeitnehmer_online_button:hover .arbeitnehmer_online_icon {
    fill: #0d0d0d;
  }
  .arbeitnehmer_online_lines {
    fill: #0090ff;
  }
  .u_online_secure_login {
    margin-bottom: 1.5em;
  }
  .u_online_overview,
  .u_online_einleitung,
  p {
    width: 100%;
  }
  .img_container.u_online_svg_2_small {
    max-width: 18.125rem;
  }
  .img_container.u_online_svg_2_small .svg_resize {
    padding-bottom: 634.48275862%;
  }
  .img_container.u_online_svg_2 {
    margin: 0 0 1.5em;
    max-width: 28.125rem;
  }
  .img_container.u_online_svg_2 .svg_resize {
    padding-bottom: 148.88888889%;
  }
  .u_online_svg_2_small {
    display: block;
    margin: 1.5em auto;
  }
  .u_online_svg_2 {
    display: none;
  }
  .u_online_2_headline {
    fill: #0d0d0d;
    font-family: "Ubuntu", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_2_headline .strong {
    fill: #0d0d0d;
    font-weight: 700;
  }
  .u_online_text {
    fill: #0d0d0d;
    font-family: "Ubuntu", sans-serif;
    font-size: 90%;
    text-anchor: middle;
  }
  .u_online_company_logo {
    fill: #0d0d0d;
  }
  .u_online_warning_icon {
    fill: #0d0d0d;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_euro_icon,
  .u_online_check_icon {
    fill: #0d0d0d;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_icon {
    fill: #0090ff;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .u_online_button .normal {
    display: block;
  }
  .u_online_button .hover {
    display: none;
  }
  .u_online_button:hover .normal {
    display: none;
  }
  .u_online_button:hover .hover {
    display: block;
  }
  .u_online_button:hover .u_online_icon {
    fill: #0090ff;
  }
  .u_online_button:hover .u_online_euro_icon,
  .u_online_button:hover .u_online_warning_icon,
  .u_online_button:hover .u_online_check_icon {
    fill: #0090ff;
  }
  .u_online_button:hover .u_online_icon {
    fill: #0d0d0d;
  }
  .u_online_lines {
    fill: #0090ff;
  }
  .u_online_benefits_flow h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 30em;
    text-align: center;
  }
  .u_online_benefits_flow .table_cell.text {
    margin: 0 auto;
    max-width: 20rem;
  }
  .u_online_benefits_flow .flow_text {
    display: none;
  }
  .u_online_benefits_flow .header {
    display: table;
    margin: 0.75em auto;
    max-width: 20rem;
    text-align: right;
  }
  .u_online_benefits_flow .header .text {
    display: table-cell;
    margin: 0;
    padding: 0 0.75em 0 0;
    vertical-align: middle;
  }
  .u_online_benefits_flow .header:after {
    background: url("../images/u_online/u_online_arrow_small.svg") no-repeat center center;
    content: "";
    display: table-cell;
    height: 4rem;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    width: 1.875rem;
  }
  .u_online_benefits_flow .footer {
    display: table;
    margin: 0.75em auto;
    max-width: 20rem;
  }
  .u_online_benefits_flow .footer .text {
    display: table-cell;
    margin: 0;
    padding: 0 0 0 0.75em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .footer:before {
    background: url("../images/u_online/u_online_arrow_small.svg") no-repeat center center;
    content: "";
    display: table-cell;
    height: 4rem;
    width: 1.875rem;
  }
  .u_online_benefits_flow .description {
    margin: 0 auto;
  }
  .u_online_benefits_flow .description ul {
    list-style: none;
    margin: 1.5em;
  }
  .u_online_benefits_flow .description li {
    margin: 0.8em 0;
    padding-left: 2.125rem;
    position: relative;
  }
  .u_online_benefits_flow .description li:before {
    background-image: url("../images/u_online/u_online_check.svg");
    content: "";
    display: block;
    height: 1.5rem;
    left: 0;
    position: absolute;
    width: 1.5rem;
  }
  .u_online_benefits_flow .lock {
    background: #e6e6e6;
    display: table;
    margin: 0 auto 0.75em;
    padding: 1.5em;
  }
  .u_online_benefits_flow .lock .text {
    display: table-cell;
    padding-left: 1.5em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .lock:before {
    background: url("../images/u_online/u_online_lock.svg") no-repeat 50% 50%;
    content: "";
    display: table-cell;
    height: 1.75rem;
    width: 1.75rem;
  }
  .u_online_benefits_flow .benefits_button {
    border: 0;
    cursor: pointer;
    display: table;
    margin: 0 auto;
    min-width: 14.375rem;
    text-decoration: none;
  }
  .u_online_benefits_flow .benefits_button > span {
    display: table-cell;
  }
  .u_online_benefits_flow .benefits_button .text {
    border-bottom-width: 0.25rem;
    border-color: #0090ff;
    border-left-width: 0.125rem;
    border-radius: 0 0 0 0;
    border-style: solid;
    border-top-width: 0.125rem;
    color: #0d0d0d;
    display: block;
    font-size: 130%;
    font-weight: 500;
    line-height: 2.75rem;
    margin-right: 0.375rem;
    padding: 0 1.5em;
    text-align: center;
    text-transform: uppercase;
    -webkit-transition: border 300ms;
    transition: border 300ms;
  }
  .u_online_benefits_flow .benefits_button:after {
    background-color: #0090ff;
    background-image: url("../images/u_online/u_online_button_arrow.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 60%;
    border-radius: 0 0 0 0;
    content: "";
    display: table-cell;
    height: 2.75rem;
    position: relative;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    vertical-align: middle;
    width: 2.75rem;
    z-index: 1;
  }
  .u_online_benefits_flow .benefits_button.visible .text {
    border-color: #0d0d0d;
  }
  .u_online_benefits_flow .benefits_button.visible:after {
    background-color: #0d0d0d;
    border-radius: 0 0 0 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .u_online_benefits_flow .benefits_button:hover .text {
    border-color: #0073cc;
  }
  .u_online_benefits_flow .benefits_button:hover:after {
    background-color: #0073cc;
  }
  .u_online_benefits_flow .flow_image {
    border: 0.5rem solid #0090ff;
    border-radius: 50%;
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    height: 15.625rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 15.625rem;
  }
  .u_online_benefits_flow .flow_image .text {
    background: rgba(0, 144, 255, 0.85);
    bottom: 0;
    color: #fff;
    left: 0;
    padding: 0.8em 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .u_online_benefits_flow .flow_image img {
    border-radius: 50%;
  }
  .folder_content_u_online {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .folder_content_u_online .infolist_download {
    -ms-flex-item-align: stretch;
        align-self: stretch;
  }
  .folder_content_u_online .infolist_top_link {
    display: none;
  }
  .folder_content_u_online .u_online_overview + h2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .folder_content_u_online .u_online_overview + h2 + p {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .folder_content_u_online .u_online_secure_login {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .folder_content_u_online .u_online_secure_login + p {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .folder_content_u_online .disclaimer {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .amount {
    display: block;
    text-align: right;
    white-space: nowrap;
  }
  .multi_columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 1.5em;
            column-gap: 1.5em;
  }
  .multi_columns li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .infolist_top_link {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 1.5em 0;
  }
  .infolist_top_link .arrow {
    background-color: #0090ff;
    border-radius: 50%;
    display: block;
    height: 3.5625rem;
    padding: 0.8em;
    position: relative;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 3.5625rem;
  }
  .infolist_top_link .arrow svg {
    fill: #fff;
  }
  .infolist_top_link .arrow:hover,
  .infolist_top_link .arrow:active,
  .infolist_top_link .arrow.active,
  .infolist_top_link .arrow :focus {
    background-color: #33a6ff;
  }
  .disclaimer {
    background: #e6e6e6;
    border-radius: 0;
    color: #0d0d0d;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .infolist_download {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: #e6e6e6;
    border-radius: 0;
    color: #0d0d0d;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .infolist_download .infolist_title {
    font-weight: 700;
  }
  .infolist_download p {
    margin-bottom: 0;
    margin-right: 1.5em;
  }
  .checklist_entry {
    border-top: 0.125rem solid #cccccc;
    margin-bottom: 0.75em;
    padding-top: 0.75em;
  }
  .checklist_entry:first-child {
    border-top: 0;
    padding-top: 0;
  }
  .kanzlei_im_netz {
    margin: 0 0 1.5em;
  }
  .kanzlei_im_netz a:after {
    clear: both;
  }
  .kanzlei_im_netz a:after,
  .kanzlei_im_netz a:before {
    content: "";
    display: table;
  }
  .kanzlei_im_netz .icon {
    background: #e6e6e6;
    border-radius: 0;
    fill: #0d0d0d;
    float: left;
    height: 3.625rem;
    margin: 0.75em 1.5em 0 0;
    padding: 0.3125rem;
    width: 3.625rem;
  }
  .kanzlei_im_netz .icon_facebook {
    background: #3664a2;
    fill: #fff;
  }
  .kanzlei_im_netz .icon_twitter {
    background: #1da1f2;
    fill: #fff;
  }
  .kanzlei_im_netz .icon_video {
    background: #ce3b00;
    fill: #fff;
  }
  .kanzlei_im_netz .icon svg {
    height: 3rem;
    width: 3rem;
  }
  .kanzlei_im_netz .description {
    display: block;
    overflow: hidden;
  }
  .newsletter_quick_subscribe_wrapper {
    margin-top: 2em;
  }
  .newsletter_quick_subscribe form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 25rem;
  }
  .newsletter_quick_subscribe .entry {
    margin-bottom: 0.3em;
    width: 100%;
  }
  .newsletter_quick_subscribe input[type="email"] {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: #fff;
    border-bottom-width: 0.125rem;
    border-color: #0090ff;
    border-left-width: 0.125rem;
    border-radius: 0 0 0 0;
    border-style: solid;
    border-top-width: 0.125rem;
    color: #0d0d0d;
    height: 2.75rem;
    margin: 0;
    padding: 0 0.75em;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 100%;
  }
  .newsletter_quick_subscribe input[type="email"]:focus {
    background: #fff;
    border-color: #0090ff;
    color: #0d0d0d;
    outline: 0;
  }
  .newsletter_quick_subscribe input[type="email"] ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #616161;
  }
  .newsletter_quick_subscribe input[type="email"] :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #616161;
    opacity: 1;
  }
  .newsletter_quick_subscribe input[type="email"] ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #616161;
    opacity: 1;
  }
  .newsletter_quick_subscribe input[type="email"] :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #616161;
  }
  .newsletter_quick_subscribe input[type="email"] ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #616161;
  }
  .newsletter_quick_subscribe button {
    background: #0090ff;
    border: 0.125rem solid #0082e6;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-color: #0090ff;
    border-radius: 0 0 0 0;
    margin: 0;
    padding: 0 1em;
    width: auto;
  }
  .newsletter_quick_subscribe button > span {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: calc(2.75rem - (0.125rem * 2));
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .newsletter_quick_subscribe button .icon {
    fill: #fff;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .newsletter_quick_subscribe button .text {
    font-weight: 200;
    padding: 0.5em 0;
    white-space: nowrap;
  }
  .newsletter_quick_subscribe button .icon + .text {
    padding-left: 0.5em;
  }
  .newsletter_quick_subscribe button:focus {
    background: #33a6ff;
    border-color: #0082e6;
    color: #fff;
  }
  .newsletter_quick_subscribe button:focus .icon {
    fill: #fff;
  }
  .newsletter_quick_subscribe button .icon svg {
    fill: #fff;
  }
  .newsletter_quick_subscribe [role="tooltip"] {
    bottom: 3rem;
    top: auto;
  }
  .toggle_offcanvas_menu {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: #2e6da0;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 3.125rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    width: 3.125rem;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 112.5%;
    font-weight: 700;
    height: 2.75rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .icon {
    height: 1.875rem;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 1.625rem;
  }
  .toggle_offcanvas_menu label .line {
    background: #fff;
    display: block;
    height: 0.25rem;
    position: absolute;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0.1875rem;
    -webkit-transition: background 300ms, top 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, top 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, top 300ms 300ms, transform 300ms;
    transition: background 300ms, top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 0.8125rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0.1875rem;
    -webkit-transition: background 300ms, bottom 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, -webkit-transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, transform 300ms;
    transition: background 300ms, bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_1 {
    top: 0.8125rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: background 300ms, top 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, top 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, top 300ms, transform 300ms 300ms;
    transition: background 300ms, top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_3 {
    bottom: 0.8125rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: background 300ms, bottom 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, -webkit-transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, transform 300ms 300ms;
    transition: background 300ms, bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .close_offcanvas_menu {
    display: block;
  }
  .offcanvas_menu_wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    min-height: 100vh;
    min-width: 20rem;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    z-index: 5000;
  }
  .offcanvas_menu_wrapper .page {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 100%;
            flex: 1 0 100%;
    min-height: 100vh;
    position: relative;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_wrapper .offcanvas_menu {
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(13, 13, 13, 0.45);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background: #fff;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 15.625rem;
            flex: 1 0 15.625rem;
    opacity: 0;
    padding: 1.5em 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    padding: 0 1.5em;
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded > a ~ ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    color: #2e6da0;
    display: block;
    font-size: 112.5%;
    font-weight: 700;
    line-height: 2.75rem;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:active,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:focus,
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:hover {
    color: #0090ff;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    color: #0d0d0d;
    display: block;
    font-weight: 200;
    padding: 0.375em 1.5em 0.375em 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active,
  .offcanvas_menu .offcanvas_menu_content > ul ul a:active,
  .offcanvas_menu .offcanvas_menu_content > ul ul a:focus,
  .offcanvas_menu .offcanvas_menu_content > ul ul a:hover {
    color: #0090ff;
    text-decoration: none;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(13, 13, 13, 0.45);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.5em;
  }
  .popup_close {
    background: #0090ff;
    border-radius: 0 0 0 0;
    float: right;
    height: 2.2rem;
    margin-right: 0.0625rem;
    padding: 0.42857143em;
    text-align: center;
    width: 2.75rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 100rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: all 300ms;
    transition: all 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    -webkit-box-shadow: 0 0 1.5625rem rgba(13, 13, 13, 0.5);
            box-shadow: 0 0 1.5625rem rgba(13, 13, 13, 0.5);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.2rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #cccccc;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  .social_media_links ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    padding: 0;
  }
  .social_media_links li {
    list-style: none;
    margin: 0;
    padding: 0 0.75em 0.75em 0;
  }
  .social_media_links a {
    border-radius: 0;
    display: inline-block;
  }
  .social_media_links a .icon {
    display: block;
    fill: #fff;
    height: 2.125rem;
    width: 2.125rem;
  }
  .social_media_links a.facebook {
    background: #3664a2;
  }
  .social_media_links a.twitter {
    background: #1da1f2;
  }
  .social_media_links a.video {
    background: #ce3b00;
  }
  .social_media_share ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin: 1.5em 0 0.75em 0;
    padding: 0;
  }
  .social_media_share li {
    list-style: none;
    margin: 0.375em 0;
    padding: 0;
  }
  .social_media_share a {
    color: #0d0d0d;
    cursor: pointer;
    display: block;
    margin-right: 0.75em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .social_media_share a > span {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 2.75rem;
  }
  .social_media_share a .icon {
    border-radius: 0;
    height: 2.125rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 2.125rem;
  }
  .social_media_share a .icon svg {
    fill: #fff;
    height: 2.125rem;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    width: 2.125rem;
  }
  .social_media_share a .text {
    display: none;
    font-weight: 700;
    padding-left: 0.75em;
  }
  .social_media_share a.facebook .icon {
    background: #3664a2;
  }
  .social_media_share a.twitter .icon {
    background: #1da1f2;
  }
  .social_media_share a.print .icon {
    background: #0090ff;
    text-align: center;
  }
  .social_media_share a.print .icon svg {
    height: 2.125rem;
    width: 1.5rem;
  }
  .social_media_share a:hover .icon,
  .social_media_share a:focus .icon,
  .social_media_share a:active .icon {
    background: #fff;
  }
  .social_media_share a:hover .icon svg,
  .social_media_share a:focus .icon svg,
  .social_media_share a:active .icon svg {
    fill: #2e6da0;
  }
  .social_media_legal_notice {
    padding: 1.5em;
  }
  .social_media_legal_notice .company_logo {
    margin: 0 auto;
    text-align: center;
  }
  .steuernews_article_slider {
    display: none;
  }
  .steuernews_navigation {
    margin: 1.5em 0;
  }
  .steuernews_navigation .button {
    margin-bottom: 0.2em;
    padding: 0.15em 2em;
    width: 18.125rem;
  }
  .steuernews_content [href*="thema"] {
    color: #0d0d0d;
  }
  .steuernews_preview .entry {
    color: #0d0d0d;
    display: block;
    padding-bottom: 3em;
    position: relative;
    text-decoration: none;
  }
  .steuernews_preview .entry:hover img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition-timing-function: ease-in-out;
            transition-timing-function: ease-in-out;
  }
  .steuernews_preview .image {
    display: block;
    overflow: hidden;
  }
  .steuernews_preview .image img {
    margin-bottom: -1.25rem;
    margin-right: 1.5em;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    width: 100%;
  }
  .steuernews_preview .title {
    display: block;
  }
  .steuernews_preview .text {
    display: block;
    margin: 0;
    padding-bottom: 1.5em;
  }
  .steuernews_preview h2.sidebar_title {
    display: none;
  }
  .steuernews_preview h2.default_title {
    display: none;
  }
  .steuernews_preview .arrow,
  .steuernews_preview .read_more {
    color: #0090ff;
  }
  .footer_sidebar .steuernews_preview {
    margin: 1em 0;
  }
  .footer_sidebar .steuernews_preview .image img {
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
  }
  .footer_sidebar .steuernews_preview .entry {
    padding: 0;
  }
  .footer_sidebar h2.sidebar_title {
    display: block;
  }
  .footer_sidebar h2.default_title {
    display: none;
  }
  .steuernews_search {
    margin: 0 0 1.5em;
  }
  .steuernews_search .search {
    margin: 0 0 0.75em;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  .news_image {
    float: left;
  }
  .news_image + * {
    padding-top: 0;
  }
  .steuernews_video_overview {
    margin-bottom: 1.5em;
  }
  .steuernews_video_overview .entry {
    display: inline-block;
  }
  .steuernews_video_overview .image {
    margin-bottom: 0.75em;
    position: relative;
  }
  .steuernews_video_overview .video_play {
    position: relative;
  }
  .steuernews_video_overview .video_play .icon {
    fill: #0090ff;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_contact_person {
    background: #e6e6e6;
    border-radius: 0;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .steuernews_contact_person .team_entry {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .steuernews_contact_person .team_entry figure {
    display: block;
  }
  .steuernews_contact_person .contact_person_entry {
    height: auto;
    margin-top: 1.5em;
    position: relative;
  }
  .steuernews_contact_person .more_team_contacts {
    background: #fff;
    border-radius: 0;
    color: #0090ff;
    display: block;
    line-height: 1.5;
    margin: 1em 0;
    padding: 0.75em;
    text-align: center;
  }
  .steuernews_newsletter_subscribe {
    background: #e6e6e6;
    border-radius: 0;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .steuernews_newsletter_subscribe .disclaimer {
    background-color: #fff;
  }
  .steuernews_sidebar {
    margin: 1.5em 0 0.75em;
  }
  .steuernews_current_issue {
    margin-bottom: 0.75em;
  }
  .keyword_register {
    margin-bottom: 0.75em;
  }
  .keyword_register:after {
    clear: both;
  }
  .keyword_register:after,
  .keyword_register:before {
    content: "";
    display: table;
  }
  .keyword_register a {
    background: #e6e6e6;
    float: left;
    line-height: 2.75rem;
    margin: 0 0.75em 0.75em 0;
    text-align: center;
    width: 2.75rem;
  }
  .steuernews_keywords ul,
  .steuernews_issues ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .steuernews_keywords a,
  .steuernews_issues a {
    background: #2e6da0;
    border-radius: 0;
    color: #fff;
    display: inline-block;
    line-height: 2.75rem;
    margin: 0 0.75em 0.75em 0;
    padding: 0 1.5em;
  }
  .steuernews_keywords a.active,
  .steuernews_issues a.active {
    background: #0090ff;
  }
  .steuernews_keywords_register .keywords_register_entries a {
    display: inline-block;
    max-width: 12.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .steuernews_call_to_action {
    background: #e6e6e6;
    border-radius: 0;
    display: inline-block;
    margin: 0 0 1.5em;
    padding: 0.75em;
  }
  .steuernews_call_to_action p {
    margin: 0;
  }
  .hightlight_keyword {
    background: #0090ff;
    color: #fff;
    outline: 0.125rem solid #0090ff;
  }
  .team_overview .outer_links {
    display: none;
    margin-top: 0.5em;
  }
  .team_overview .outer_links a {
    text-decoration: none;
  }
  .team_overview .photo {
    margin-bottom: -0.375rem;
  }
  .team_overview .info {
    background-color: #2e6da0;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    margin-top: 1.5em;
    padding: 4.5em 3em 3em 3em;
    position: relative;
  }
  .team_overview .info:before {
    border-color: #fff transparent transparent transparent;
    border-style: solid;
    border-width: 2.25em 2.25em 0 2.25em;
    content: "";
    display: block;
    height: 0;
    left: 3em;
    position: absolute;
    top: 0;
    width: 0;
  }
  .team_overview .top_info .title {
    padding: 0;
  }
  .team_overview .top_info .contact {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .team_overview .top_info .contact > span {
    margin-right: 0.2em;
  }
  .team_overview .center_info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
  }
  .team_overview .center_info p {
    margin: 0;
  }
  .team_overview .center_info:before,
  .team_overview .center_info:after {
    border-color: transparent transparent transparent #fff;
    border-style: solid;
    border-width: 2.25em 0 2.25em 2.25em;
    content: "";
    display: none;
    height: 0;
    position: absolute;
    width: 0;
  }
  .team_overview .center_info:before {
    left: -4.5em;
    top: -4.05em;
  }
  .team_overview .center_info:after {
    bottom: -4.05em;
    left: -4.5em;
  }
  .team_overview .bottom_info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .team_overview .bottom_info .button {
    background: transparent;
    border: 0.125rem solid #fff;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    margin-top: 0.5em;
    padding: 0.15em 1.2em;
  }
  .team_overview .bottom_info .button > span {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: calc(2.75rem - (0.125rem * 2));
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .team_overview .bottom_info .button .icon {
    fill: #fff;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .team_overview .bottom_info .button .text {
    font-weight: 200;
    padding: 0.5em 0;
    white-space: nowrap;
  }
  .team_overview .bottom_info .button .icon + .text {
    padding-left: 0.5em;
  }
  .team_overview .bottom_info .button:focus {
    -webkit-box-shadow: 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
            box-shadow: 0 0 0 0.375rem rgba(0, 144, 255, 0.2);
    outline: 0;
    text-decoration: none;
    background: #fff;
    border-color: #fff;
    color: #0090ff;
  }
  .team_overview .bottom_info .button:focus .icon {
    fill: #0090ff;
  }
  .team_overview .bottom_info .button:hover {
    text-decoration: none;
    background: #fff;
    border-color: #fff;
    color: #0090ff;
  }
  .team_overview .bottom_info .button:hover .icon {
    fill: #0090ff;
  }
  .team_overview .bottom_info .button:active,
  .team_overview .bottom_info .button.active {
    -webkit-box-shadow: 0 0 0.375rem #0073cc inset;
            box-shadow: 0 0 0.375rem #0073cc inset;
    background: #0090ff;
    border: 0.125rem solid #0073cc;
    color: #fff;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .team_overview .bottom_info .button:active > span,
  .team_overview .bottom_info .button.active > span {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: calc(2.75rem - (0.125rem * 2));
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .team_overview .bottom_info .button:active .icon,
  .team_overview .bottom_info .button.active .icon {
    fill: #fff;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .team_overview .bottom_info .button:active .text,
  .team_overview .bottom_info .button.active .text {
    font-weight: 200;
    padding: 0.5em 0;
    white-space: nowrap;
  }
  .team_overview .bottom_info .button:active .icon + .text,
  .team_overview .bottom_info .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .team_overview .title,
  .team_overview a {
    color: #fff;
  }
  .team_overview a {
    text-decoration: underline;
  }
  .team_overview a:hover,
  .team_overview a:focus,
  .team_overview a:active {
    text-decoration: none;
  }
  .teaser_overview .teaser {
    margin: 2em 0;
    position: relative;
  }
  .teaser_overview .icon {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-item-align: center;
        align-self: center;
    border: 0.25rem solid #616161;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 6.875rem;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 1.5em;
    margin-right: 0;
    min-width: 6.875rem;
    -webkit-transition: border 300ms;
    transition: border 300ms;
    width: 6.875rem;
  }
  .teaser_overview .icon svg {
    height: 3.125rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 3.125rem;
  }
  .teaser_overview .teaser_content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: 100%;
  }
  .teaser_overview .teaser_content p {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    margin-bottom: 1.5em;
  }
  .teaser_overview .teaser_content .button {
    width: 15rem;
  }
  .teaser_overview .title {
    padding: 0;
  }
  .teaser_overview .teaser:hover .icon,
  .teaser_overview .teaser:focus .icon {
    border: 0.25rem solid #2e6da0;
  }
  .teaser_overview .teaser:hover svg,
  .teaser_overview .teaser:focus svg {
    fill: #0090ff;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #e6e6e6;
    border: 0.125rem solid #cccccc;
    border-radius: 0;
    color: #0d0d0d;
    font-size: 85%;
    font-weight: 400;
    line-height: 2.0625rem;
    padding: 0 0.75em;
    position: absolute;
    right: 0;
    text-align: center;
    top: -2.4375rem;
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #e6e6e6;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #cccccc;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30em) {
  .row.around_small {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35em) {
  .row.around_medium {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5em) {
  .row.around_large {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875em) {
  .row.around_xlarge {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75em) {
  .row.around_xxlarge {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75em) {
  .row.around_xxxlarge {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2)
                                     * (100vw - 20rem)
                                     / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30em) {
  .span1_small {
    -ms-flex-preferred-size: 8.33333333%;
        flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .span2_small {
    -ms-flex-preferred-size: 16.66666667%;
        flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .span3_small {
    -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .span4_small {
    -ms-flex-preferred-size: 33.33333333%;
        flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .span5_small {
    -ms-flex-preferred-size: 41.66666667%;
        flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .span6_small {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .span7_small {
    -ms-flex-preferred-size: 58.33333333%;
        flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .span8_small {
    -ms-flex-preferred-size: 66.66666667%;
        flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .span9_small {
    -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .span10_small {
    -ms-flex-preferred-size: 83.33333333%;
        flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .span11_small {
    -ms-flex-preferred-size: 91.66666667%;
        flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .span12_small {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
}
@media screen and (min-width: 35em) {
  .span1_medium {
    -ms-flex-preferred-size: 8.33333333%;
        flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .span2_medium {
    -ms-flex-preferred-size: 16.66666667%;
        flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .span3_medium {
    -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .span4_medium {
    -ms-flex-preferred-size: 33.33333333%;
        flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .span5_medium {
    -ms-flex-preferred-size: 41.66666667%;
        flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .span6_medium {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .span7_medium {
    -ms-flex-preferred-size: 58.33333333%;
        flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .span8_medium {
    -ms-flex-preferred-size: 66.66666667%;
        flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .span9_medium {
    -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .span10_medium {
    -ms-flex-preferred-size: 83.33333333%;
        flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .span11_medium {
    -ms-flex-preferred-size: 91.66666667%;
        flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .span12_medium {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
}
@media screen and (min-width: 47.5em) {
  .span1_large {
    -ms-flex-preferred-size: 8.33333333%;
        flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .span2_large {
    -ms-flex-preferred-size: 16.66666667%;
        flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .span3_large {
    -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .span4_large {
    -ms-flex-preferred-size: 33.33333333%;
        flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .span5_large {
    -ms-flex-preferred-size: 41.66666667%;
        flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .span6_large {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .span7_large {
    -ms-flex-preferred-size: 58.33333333%;
        flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .span8_large {
    -ms-flex-preferred-size: 66.66666667%;
        flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .span9_large {
    -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .span10_large {
    -ms-flex-preferred-size: 83.33333333%;
        flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .span11_large {
    -ms-flex-preferred-size: 91.66666667%;
        flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .span12_large {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
}
@media screen and (min-width: 61.875em) {
  .span1_xlarge {
    -ms-flex-preferred-size: 8.33333333%;
        flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .span2_xlarge {
    -ms-flex-preferred-size: 16.66666667%;
        flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .span3_xlarge {
    -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .span4_xlarge {
    -ms-flex-preferred-size: 33.33333333%;
        flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .span5_xlarge {
    -ms-flex-preferred-size: 41.66666667%;
        flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .span6_xlarge {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .span7_xlarge {
    -ms-flex-preferred-size: 58.33333333%;
        flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .span8_xlarge {
    -ms-flex-preferred-size: 66.66666667%;
        flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .span9_xlarge {
    -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .span10_xlarge {
    -ms-flex-preferred-size: 83.33333333%;
        flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .span11_xlarge {
    -ms-flex-preferred-size: 91.66666667%;
        flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .span12_xlarge {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
}
@media screen and (min-width: 75em) {
  .span1_xxlarge {
    -ms-flex-preferred-size: 8.33333333%;
        flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .span2_xxlarge {
    -ms-flex-preferred-size: 16.66666667%;
        flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .span3_xxlarge {
    -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .span4_xxlarge {
    -ms-flex-preferred-size: 33.33333333%;
        flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .span5_xxlarge {
    -ms-flex-preferred-size: 41.66666667%;
        flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .span6_xxlarge {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .span7_xxlarge {
    -ms-flex-preferred-size: 58.33333333%;
        flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .span8_xxlarge {
    -ms-flex-preferred-size: 66.66666667%;
        flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .span9_xxlarge {
    -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .span10_xxlarge {
    -ms-flex-preferred-size: 83.33333333%;
        flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .span11_xxlarge {
    -ms-flex-preferred-size: 91.66666667%;
        flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .span12_xxlarge {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
}
@media screen and (min-width: 93.75em) {
  .span1_xxxlarge {
    -ms-flex-preferred-size: 8.33333333%;
        flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .span2_xxxlarge {
    -ms-flex-preferred-size: 16.66666667%;
        flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .span3_xxxlarge {
    -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .span4_xxxlarge {
    -ms-flex-preferred-size: 33.33333333%;
        flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .span5_xxxlarge {
    -ms-flex-preferred-size: 41.66666667%;
        flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .span6_xxxlarge {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .span7_xxxlarge {
    -ms-flex-preferred-size: 58.33333333%;
        flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .span8_xxxlarge {
    -ms-flex-preferred-size: 66.66666667%;
        flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .span9_xxxlarge {
    -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .span10_xxxlarge {
    -ms-flex-preferred-size: 83.33333333%;
        flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .span11_xxxlarge {
    -ms-flex-preferred-size: 91.66666667%;
        flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .span12_xxxlarge {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
}
@media screen and (min-width: 35em) {
  .startpage header {
    height: 70vh;
  }
}
@media screen and (min-width: 61.875em) {
  .startpage header {
    height: 100vh;
  }
}
@media screen and (min-width: 47.5em) {
  .header_content .container {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media screen and (orientation: landscape) and (max-width: 47.5em) {
  .header_content {
    padding: 1.5em 0;
  }
}
@media screen and (min-width: 47.5em) {
  .company_logo {
    max-width: 20rem;
  }
}
@media screen and (min-width: 61.875em) {
  .company_logo {
    max-width: 26rem;
  }
}
@media screen and (min-width: 61.875em) {
  .go_to_bottom {
    display: block;
  }
}
@media screen and (min-width: 30em) {
  .header_background {
    background-position: 20% 100%;
  }
}
@media screen and (orientation: landscape) and (min-width: 35em) and (max-width: 47.5em) {
  .startpage header {
    height: 83vh;
  }
  .startpage header .header_background {
    background-position: 50% -10%;
  }
}
@media screen and (min-width: 47.5em) {
  main {
    margin: 3em 0;
  }
}
@media screen and (min-width: 75em) {
  main {
    margin: 5em 0;
  }
}
@media screen and (min-width: 30em) {
  .address_vcard_button {
    margin: 0.5em 0 2em 0;
    width: auto;
  }
}
@media screen and (min-width: 30em) {
  .address_call_button {
    margin: 0.5em 0 0 0;
    width: auto;
  }
}
@media screen and (min-width: 93.75em) {
  .onlinetools_popup_overview .button {
    padding: 0.15em 2.7em;
  }
}
@media screen and (min-width: 35em) {
  .infolist_overview {
    padding: 3.75em 3em 3em 3em;
  }
}
@media screen and (min-width: 35em) {
  .download_overview {
    padding: 3.75em 3em 3em 3em;
  }
}
@media screen and (min-width: 75em) {
  aside.sidebar {
    display: block;
  }
}
@media screen and (min-width: 93.75em) {
  aside.sidebar .button {
    padding: 0.15em 2.7em;
  }
}
@media screen and (min-width: 75em) {
  footer .related_links {
    margin-top: 0;
  }
}
@media screen and (min-width: 75em) {
  footer .related_links ul {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}
@media screen and (min-width: 20rem) {
  [role="tab"] .description {
    font-size: calc(0.875rem + 0.125
                            * (100vw - 20rem)
                            / (93.75 - 20));
  }
}
@media screen and (min-width: 93.75em) {
  [role="tab"] .description {
    font-size: 1rem;
  }
}
@media screen and (min-width: 61.875em) {
  .edit_in_cms {
    bottom: 1.25rem;
    display: inline-block;
    background: #0090ff;
    border-radius: 0;
    color: #fff;
    padding: 0.75em 1.5em;
    position: fixed;
    right: 1.25rem;
    text-decoration: none;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    z-index: 9999;
  }
  .edit_in_cms:hover {
    background: #0d0d0d;
    color: #fff;
  }
}
@media screen and (min-width: 47.5em) {
  .dropdown_menu {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
@media screen and (min-width: 35em) and (max-width: 61.875em) {
  .dropdown_menu > ul > li:after {
    height: 0.625rem;
    margin: 0.125rem;
    width: 0.625rem;
  }
  .dropdown_menu > ul > li a {
    padding-left: 0;
    padding-right: 0.3125rem;
  }
}
@media screen and (min-width: 35em) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35em) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 47.5em) {
  .img_container.arbeitnehmer_online_svg_2_small {
    display: none;
  }
}
@media screen and (min-width: 47.5em) {
  .img_container.arbeitnehmer_online_svg_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5em) {
  .arbeitnehmer_online_2_headline {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5em) {
  .arbeitnehmer_online_text {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5em) {
  .img_container.u_online_svg_2_small {
    display: none;
  }
}
@media screen and (min-width: 47.5em) {
  .img_container.u_online_svg_2 {
    display: block;
  }
}
@media screen and (min-width: 47.5em) {
  .u_online_2_headline {
    font-size: 80%;
  }
}
@media screen and (min-width: 47.5em) {
  .u_online_text {
    font-size: 80%;
  }
}
@media screen and (min-width: 75em) {
  .u_online_benefits_flow .flow_image {
    height: 9.375rem;
    width: 9.375rem;
  }
}
@media screen and (min-width: 93.75em) {
  .u_online_benefits_flow .flow_image {
    height: 12.5rem;
    width: 12.5rem;
  }
}
@media screen and (min-width: 61.875em) {
  .u_online_benefits_flow .table {
    display: table;
    margin: 0 auto;
    padding: 0;
  }
  .u_online_benefits_flow .table_cell {
    display: table-cell;
    padding: 0 1.5em;
    vertical-align: middle;
  }
  .u_online_benefits_flow .table_cell.text {
    max-width: 31.25rem;
  }
  .u_online_benefits_flow .flow_text {
    display: block;
  }
  .u_online_benefits_flow .header {
    display: block;
    text-align: center;
  }
  .u_online_benefits_flow .header .text {
    display: block;
    margin: 0 auto 0.75em;
    padding: 0;
    text-align: center;
    width: 80%;
  }
  .u_online_benefits_flow .header:after {
    background: url("../images/u_online/u_online_arrow.svg") no-repeat;
    display: block;
    height: 0;
    margin: 0 auto;
    padding-bottom: 16.15853659%;
    -webkit-transform: rotate(0);
            transform: rotate(0);
    width: auto;
  }
  .u_online_benefits_flow .footer {
    display: block;
    text-align: center;
  }
  .u_online_benefits_flow .footer .text {
    display: block;
    margin: 0.75em auto 0;
    padding: 0;
    text-align: center;
    width: 80%;
  }
  .u_online_benefits_flow .footer:before {
    background: url("../images/u_online/u_online_arrow.svg") no-repeat;
    content: "";
    display: block;
    height: 0;
    margin: 0 auto 0.8em;
    padding-bottom: 16.15853659%;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    width: auto;
  }
  .u_online_benefits_flow .description {
    max-height: none;
    overflow: none;
    padding: 0 0.8em;
  }
  .u_online_benefits_flow .lock {
    margin-bottom: 1.5em;
  }
  .u_online_benefits_flow .lock:before {
    height: 3.5rem;
    width: 3.5rem;
  }
  .u_online_benefits_flow .benefits_button {
    display: none;
  }
}
@media screen and (min-width: 47.5em) {
  .newsletter_quick_subscribe_wrapper {
    margin-top: 0;
  }
}
@media screen and (min-width: 47.5em) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (max-width: 47.4375em) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: translate(-15.625rem, 0);
            transform: translate(-15.625rem, 0);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_content,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_content {
    opacity: 1;
    -webkit-transition: none;
    transition: none;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
}
@media screen and (max-width: 47.4375em) and (min-width: 30em) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .page,
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    -webkit-transform: translate(-21.875rem, 0);
            transform: translate(-21.875rem, 0);
  }
}
@media screen and (min-width: 30em) {
  .offcanvas_menu {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 21.875rem;
            flex: 1 0 21.875rem;
  }
}
@media screen and (min-width: 61.875em) {
  .steuernews_article_slider {
    display: block;
    height: 16.125rem;
    overflow: hidden;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .steuernews_article_slider .slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    visibility: hidden;
    z-index: 0;
  }
  .steuernews_article_slider .slide .image {
    min-width: 24.1875rem;
    width: 24.1875rem;
  }
  .steuernews_article_slider .slide .description {
    padding: 0 4.5em 0 3em;
    width: 100%;
  }
  .steuernews_article_slider .slide .description .title {
    margin: 0;
  }
  .steuernews_article_slider .slide .description .sub_title {
    display: block;
    margin-bottom: 0.75em;
  }
  .steuernews_article_slider .slide_anchor:checked + .slide {
    opacity: 1;
    visibility: visible;
    z-index: 5;
  }
  .steuernews_article_slider .control_slides {
    background: #e6e6e6;
    display: block;
    fill: #0d0d0d;
    height: 3.75rem;
    margin-top: -1.875rem;
    padding: 1em;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transition: background 300ms;
    transition: background 300ms;
    width: 3.75rem;
    z-index: 15;
  }
  .steuernews_article_slider .control_slides:focus,
  .steuernews_article_slider .control_slides:hover {
    fill: #0090ff;
    outline: 0;
  }
  .steuernews_article_slider .prev_slide {
    left: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .steuernews_article_slider .next_slide {
    right: 0;
  }
}
@media screen and (min-width: 61.875em) {
  .steuernews_navigation {
    margin: 3em 0;
  }
}
@media screen and (min-width: 47.5em) and (max-width: 65.9375rem) {
  .footer_sidebar h2.sidebar_title {
    min-height: 4.6875rem;
  }
}
@media screen and (min-width: 61.875em) {
  .news_image {
    float: right;
  }
}
@media screen and (min-width: 75em) and (max-width: 93.75em) {
  .team_overview .outer_links {
    display: block;
  }
}
@media screen and (min-width: 75em) {
  .team_overview .info {
    margin-top: 0;
    padding: 3em 3em 3em 4.5em;
  }
  .team_overview .info:before {
    display: none;
  }
}
@media screen and (max-width: 30em) {
  .team_overview .top_info .contact {
    display: block;
  }
  .team_overview .top_info .contact span {
    display: block;
  }
}
@media screen and (min-width: 75em) and (max-width: 93.75em) {
  .team_overview .top_info .contact {
    display: block;
  }
  .team_overview .top_info .contact span {
    display: block;
  }
}
@media screen and (min-width: 75em) {
  .team_overview .top_info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
}
@media screen and (min-width: 93.75em) {
  .team_overview .top_info {
    -ms-flex-preferred-size: 33%;
        flex-basis: 33%;
  }
}
@media screen and (min-width: 75em) {
  .team_overview .center_info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
  .team_overview .center_info:before,
  .team_overview .center_info:after {
    display: block;
  }
}
@media screen and (min-width: 75em) and (max-width: 93.75em) {
  .team_overview .center_info:after {
    display: none;
  }
}
@media screen and (min-width: 93.75em) {
  .team_overview .center_info {
    -ms-flex-preferred-size: 33%;
        flex-basis: 33%;
    margin: 3.4em 0;
  }
}
@media screen and (min-width: 93.75em) {
  .team_overview .bottom_info .button {
    padding: 0.15em 2.7em;
  }
}
@media screen and (min-width: 75em) and (max-width: 93.75em) {
  .team_overview .bottom_info {
    display: none;
  }
}
@media screen and (min-width: 93.75em) {
  .team_overview .bottom_info {
    -ms-flex-preferred-size: 33%;
        flex-basis: 33%;
  }
}
@media screen and (min-width: 35em) {
  .teaser_overview .teaser {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
@media screen and (min-width: 61.875em) {
  .teaser_overview .teaser {
    margin: 3em 0;
  }
}
@media screen and (min-width: 35em) {
  .teaser_overview .icon {
    margin-bottom: 0;
    margin-right: 3em;
  }
}
@media screen and (min-width: 61.875em) {
  .teaser_overview .teaser_content {
    margin-right: 4em;
  }
}
@media print {
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.5em;
    page-break-inside: avoid;
  }
  button,
  .btn {
    display: none!important;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 700;
    margin-bottom: 0.75em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    display: block;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.5em;
  }
  .company_logo img {
    width: 50%;
  }
  .footer_sidebar {
    display: none!important;
  }
  .footer_sidebar figure {
    display: none!important;
  }
  .steuernews_preview a {
    display: block!important;
  }
  .steuernews_preview a .title,
  .steuernews_preview a .text {
    display: block!important;
  }
  .steuernews_preview .arrow {
    display: none!important;
  }
  .sidebar_title {
    display: block!important;
  }
  .default_title {
    display: none!important;
  }
  .accordion .description {
    display: block!important;
    font-size: 80%!important;
    margin-top: 0.625rem !important;
  }
  .newspage .sidebar_title {
    display: none!important;
  }
  .qr_code_print {
    border-top: 0.125rem solid;
    margin: 1.5em 0 0;
    padding: 0.75em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
